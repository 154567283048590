<template>
  <crud-component v-bind="$props" primaryKey="_id" :crudNeedsRefresh="crudNeedsRefresh"> </crud-component>
</template>
<script>
import apiErrors from '@/mixins/api-errors';
import swal from 'sweetalert2/dist/sweetalert2';
import EventBus from '@/stores/event-bus';
import ProgressNotification from '@/components/UIComponents/ProgressNotification.vue';

export default {
  props: {
    name: String,
    modelName: String,
    model: {
      type: Object,
    },
    schema: {
      type: Object,
    },
    options: {
      default: () => ({
        mode: 'local',
        actions: {
          create: true,
          edit: true,
          view: true,
          delete: true,
          import: false,
          export: false,
        },
        columns: [],
      }),
    },
  },
  components: {
    //  'vue-form-generator': VueFormGenerator.component
  },
  data() {
    return {
      crudNeedsRefresh: false,
      projectsList: [],
      colors: {
        'bg-primary': 'Navy blue',
        'bg-success': 'Green',
        'bg-warning': 'Orange',
        'bg-danger': 'Red',
        'bg-default': 'Violet',
        'bg-info': 'Light Blue',
        'bg-secondary': 'Secondary',
        'bg-black': 'Black',
        'bg-white': 'White',
      },
    };
  },
  mixins: [apiErrors],
  created() {},
  mounted() {
    this.$modal = $('#goodieModal');
  },
  methods: {
    importProject() {
      this.$notify({
        title: 'Importing projects from AirTable',
        timeout: 0,
        verticalAlign: 'top',
        horizontalAlign: 'center',
        component: ProgressNotification,
      });
      this.$http
        .get('/airtable/crm/projects')
        .then((res) => {
          this.$notifications.clear();
          this.projectsList = res.data.body;
          const projectsList = {};
          this.projectsList.forEach((p) => {
            projectsList[p.Code] = p.Name ? `${p.Code} ${p.Name}` : p.Code;
          });

          swal
            .mixin({
              reverseButtons: true,
              confirmButtonText: 'Next &rarr;',
              showCancelButton: true,
              progressSteps: ['1', '2', '3', '4'],
            })
            .queue([
              {
                input: 'select',
                title: 'Choose your project',
                inputOptions: projectsList,
                inputClass: 'form-control',
              },
              {
                title: 'Color',
                input: 'select',
                inputClass: 'form-control',
                inputOptions: this.colors,
              },
            ])
            .then((result) => {
              let myProject = this.projectsList.find((p) => result.value && p.Code === result.value[0]);
              myProject = myProject || {};
              if (result.value) {
                this.$http
                  .post('/project', {
                    code: result.value[0],
                    name: myProject.Name,
                    color: {
                      id: result.value[1],
                      label: this.colors[result.value[1]],
                    },
                    status: 'active',
                    budget: 0,
                    contractValue: myProject['Contract HT'] || 0,
                    trelloBoard: myProject['Trello Board'] || undefined,
                    airtableId: myProject.airtableId || '',
                    airtableLink: myProject.airtableId
                      ? `https://airtable.com/tbl5xezAVxcr2NY97/viwqguVEP1cYoFmO2/${myProject.airtableId}`
                      : undefined,
                  })
                  .then((rs) => {
                    EventBus.$emit('project_projects:created', rs.data);
                    this.crudNeedsRefresh = true;
                    swal({
                      title: `Project ${result.value[0]} created`,
                      type: 'success',
                    });
                    this.$store.dispatch('refreshListOfValues');
                    this.$store.dispatch('getProjects');
                  });
              }
            });
        })
        .catch((err) => {
          console.error(err.response);
          this.$notify({ message: JSON.stringify(err) });
        });
    },
  },
};
</script>
<style></style>
