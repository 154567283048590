import _ from 'lodash';

import config from '@/config';
import rolesMixin from '@/mixins/rolesMixin';

import Iframe from '@/components/Dashboard/Views/Iframe.vue';

// unused pages
import Notifications from '@/components/Dashboard/Views/Notifications.vue';
// Admin pages

import Overview from '@/components/Dashboard/Views/Overview.vue';
import BusinessReport from '@/components/Dashboard/Views/BusinessReport.vue';
import ProjectsGantt from '@/pages/project/ProjectsGantt.vue';
import MilestoneComponent from '@/pages/project/MilestoneComponent.vue';

import UserProfile from '@/pages/user/UserProfile.vue';
import Profile from '@/pages/user/Profile.vue';
import AddressBook from '@/components/Dashboard/Views/AddressBook.vue';
import DashboardLayout from '@/components/Dashboard/Layout/DashboardLayout.vue';
import EnyoVueComponents from 'vue-enyo-components';
// import CrudComponent from '@/components/Dashboard/Views/CrudComponent.vue';
import AbsenceComponent from '@/components/Dashboard/Views/AbsenceComponent.vue';
import RemoteDayComponent from '@/components/Dashboard/Views/RemoteDayComponent.vue';

import ReportComponent from '@/pages/project/ReportComponent.vue';
import ReportViewComponent from '@/pages/project/ReportViewComponent.vue';
import ProjectComponent from '@/pages/project/ProjectComponent.vue';
import ProjectDetailsComponent from '@/pages/project/ProjectDetailsComponent.vue';
// import ProjectViewComponent from '@/pages/project/ProjectViewComponent.vue';

import DevopsOverview from '@/pages/devops/Overview.vue';
import DeploymentDetailPage from '@/pages/devops/DeploymentDetailPage.vue';

import GmailAlert from '@/pages/help-desk/GmailAlert.vue';
import DailyStandupComponent from '@/pages/daily-standup/DailyStandupComponent.vue';
import WriteDailyStandupComponent from '@/pages/daily-standup/WriteDailyStandupComponent.vue';
import ViewDailyStandupComponent from '@/pages/daily-standup/ViewDailyStandupComponent.vue';
import TrelloCards from '@/components/Dashboard/Views/TrelloCards.vue';
import Login from '@/components/GeneralViews/Login.vue';
// GeneralViews
import NotFound from '@/components/GeneralViews/NotFoundPage.vue';
import Register from '@/components/GeneralViews/Register.vue';
import Reset from '@/components/GeneralViews/Reset.vue';
import store from '@/stores';

const { CrudComponent } = EnyoVueComponents;

const authGuard = (to, from, next) => {
  if (store.state.user && store.state.user._id) {
    return next();
  }
  next('/login');
};

const roleGuard = (role) => (to, from, next) => {
  if (!store.state.user || !store.state.user._id) {
    store.commit('logout');
    return next('/login');
  }

  if (!store.state.user.roles || !rolesMixin.methods.userHasRole(role, store)) {
    next('/');
    if (process.env.NODE_ENV !== 'production') {
      console.warn('Rolegard:: missing role ', role, 'redirecting');
    }
    return;
  }
  return next();
};

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/auth/google/callback',
    name: 'Google',
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/reset-password/:token',
    name: 'Reset',
    component: Reset,
    props: true,
  },
  {
    path: '/',
    name: 'Home',
    redirect: '/app/overview',
  },
  {
    path: '/app',
    component: DashboardLayout,
    redirect: '/app/overview',
    beforeEnter: authGuard,
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview,
        beforeEnter: authGuard,
      },
      {
        path: 'business-report',
        name: 'BusinessReport',
        component: BusinessReport,
        beforeEnter: roleGuard('INTERNAL'),
      },
      {
        path: 'devops/overview',
        name: 'devops-overview',
        component: DevopsOverview,
        // beforeEnter: roleGuard('DEVOPS')
      },
      {
        path: 'devops/pm2',
        name: 'devops-pm2',
        component: Iframe,
        props: {
          url: 'http://pm2-gui.enyosolutions.com/#/hosts/localhost',
        },
      },
      {
        path: 'devops/init',
        name: 'devops-init',
        component: Iframe,
        props: {
          url: '/devops',
        },
      },
      {
        path: 'user',
        name: 'User',
        component: UserProfile,
        beforeEnter: authGuard,
      },
      {
        path: 'agenda',
        name: 'view-agenda',
        component: Iframe,
        props: {
          url: `${
            'https://calendar.google.com/calendar/embed?mode=WEEK&' +
            'wkst=1&bgcolor=%23339999&color=%231B887A' +
            '&src='
          }${
            store && store.state && store.state.user ? store.state.user.email : ''
          }&color=%23B1365F&ctz=Europe%2FLisbon`,
        },
      },
      {
        path: 'address-book',
        name: 'addressbook',
        component: AddressBook,
        beforeEnter: authGuard,
      },
      {
        path: 'project/gantt',
        name: 'Projects gantt',
        component: ProjectsGantt,
        beforeEnter: roleGuard('PROJECTMANAGER'),
      },
      {
        name: 'project',
        path: 'project/project',
        component: ProjectComponent,
        beforeEnter: roleGuard('PROJECTMANAGER'),
        props: {
          name: 'project',
          modelName: 'project',
          options: {
            stats: true,
            viewPath: '/app/project/project/:id',
            modalMode: 'slide',
            actions: {
              create: true,
              edit: true,
              view: true,
              delete: true,
            },
            queryParams: {
              sort: { completed: 'asc' },
            },
          },
        },
        children: [
          {
            name: 'project-edit',
            path: ':id/edit',
            component: ProjectComponent,
            beforeEnter: roleGuard('PROJECTMANAGER'),
            props: {
              modelName: 'project-edit',
              options: {
                modalMode: 'slide',
                actions: {
                  create: true,
                  edit: true,
                  view: true,
                  delete: true,
                },
              },
            },
          },
        ],
      },
      {
        name: 'Projects',
        path: 'project/project/:id',
        component: ProjectDetailsComponent,
        beforeEnter: roleGuard('PROJECTMANAGER'),
        props: {
          name: 'project-view',
          options: {
            modalMode: 'slide',
            stats: true,
            viewPath: '/app/project/project/:id',
          },
        },
      },

      {
        path: 'project/report',
        component: ReportComponent,
        beforeEnter: roleGuard('PROJECTMANAGER'),
        props: {
          modelName: 'report',
          options: {
            stats: true,
            modalMode: 'fullpage',
            viewPath: '/app/project/report/:id',
            actions: {
              create: true,
              edit: true,
              view: true,
              delete: true,
              filter: true,
            },
          },
        },
        children: [
          {
            name: 'report-edit',
            path: ':id/edit',
            component: ReportComponent,
            beforeEnter: roleGuard('PROJECTMANAGER'),
            props: {
              modelName: 'report',
              options: {
                viewPath: '/app/project/report/:id',
                modalMode: 'fullpage',
                actions: {
                  create: true,
                  edit: true,
                  view: true,
                  delete: true,
                  filter: true,
                },
              },
            },
          },
        ],
      },
      {
        name: 'report-view',
        path: 'project/report/:id',
        component: ReportViewComponent,
        beforeEnter: roleGuard('PROJECTMANAGER'),
        props: {
          modelName: 'fullpage',
          options: {
            modalMode: 'slide',
            actions: {
              create: true,
              edit: true,
              view: true,
              delete: true,
              filter: true,
            },
          },
        },
      },
      {
        path: '/app/helpdesk/gmail-alert',
        component: GmailAlert,
        beforeEnter: roleGuard('INTERNAL'),
        props: {
          modelName: 'gmail_alert',
          options: {
            stats: false,
            actions: {
              create: true,
              edit: true,
              view: true,
              delete: true,
            },
          },
        },
        children: [
          {
            name: 'gmail-alert-view',
            path: ':id',
            component: GmailAlert,
            beforeEnter: roleGuard('INTERNAL'),
            props: {
              modelName: 'gmail_alert',
              options: {
                actions: {
                  create: true,
                  edit: true,
                  view: true,
                  delete: true,
                },
              },
            },
          },
          {
            name: 'gmail-alert-edit',
            path: ':id/edit',
            component: GmailAlert,
            beforeEnter: roleGuard('INTERNAL'),
            props: {
              modelName: 'gmail_alert',
              options: {
                actions: {
                  create: true,
                  edit: true,
                  view: true,
                  delete: true,
                },
              },
            },
          },
        ],
      },

      {
        path: 'project/milestone',
        component: MilestoneComponent,
        beforeEnter: roleGuard('PROJECTMANAGER'),
        props: {
          name: 'milestone',
          options: {
            stats: true,
            actions: {
              create: true,
              edit: true,
              view: true,
              delete: true,
            },
          },
        },
        children: [
          {
            name: 'milestone-view',
            path: ':id',
            component: MilestoneComponent,
            beforeEnter: roleGuard('PROJECTMANAGER'),
            props: {
              name: 'milestone',
              options: {
                actions: {
                  create: true,
                  edit: true,
                  view: true,
                  delete: true,
                },
              },
            },
          },
          {
            name: 'milestone-edit',
            path: ':id/edit',
            component: MilestoneComponent,
            beforeEnter: roleGuard('PROJECTMANAGER'),
            props: {
              name: 'milestone',
              options: {
                actions: {
                  create: true,
                  edit: true,
                  view: true,
                  delete: true,
                },
              },
            },
          },
        ],
      },
      {
        path: 'trello/my-cards',
        name: 'my-trello-cards',
        component: TrelloCards,
        beforeEnter: authGuard,
      },
      {
        path: 'projects/new',
        name: 'Create new project',
        component: Iframe,
        props: {
          url: 'https://airtable.com/embed/shrcNqwcbhMuL8uzu?backgroundColor=purple',
        },
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: Notifications,
        beforeEnter: authGuard,
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
        beforeEnter: authGuard,
      },
      {
        component: DailyStandupComponent,
        beforeEnter: authGuard,
        name: 'list',
        path: 'daily_standup/list',
        props: {
          identity: 'daily_standup',
          name: 'daily_standup',
          modelName: 'daily_standup',
          options: {
            url: 'daily_standup',
            viewPath: ':id',
            editPath: ':id/edit',
            createPath: 'write',
            deletePermitted: ['ADMIN'],
            stats: true,
            mode: 'remote',
            createLabel: 'app.labels.write_daily_standup',
            saveSearchDatas: true,
            actions: {
              create: true,
              view: true,
              edit: true,
              delete: true,
            },
          },
        },
      },
      {
        name: 'dailyStandupWrite',
        path: 'daily_standup/write',
        component: WriteDailyStandupComponent,
      },
      {
        name: 'dailyStandupView',
        path: 'daily_standup/:id',
        component: ViewDailyStandupComponent,
      },
      {
        name: 'dailyStandupEdit',
        path: 'daily_standup/:id/edit',
        component: WriteDailyStandupComponent,
      },
      {
        name: 'api_log',
        path: 'api_log',
        component: CrudComponent,
        beforeEnter: authGuard,
        props: {
          name: 'api_log',
          mode: 'remote',
          options: {
            url: 'crud/api_log',
            stats: true,
            actions: {
              view: true,
              delete: true,
            },
          },
        },
        children: [
          {
            name: 'api_log-view',
            path: ':id',
            mode: 'remote',
            component: CrudComponent,
            props: {
              name: 'api_log',
              options: {
                actions: {
                  view: true,
                },
              },
            },
          },
        ],
      },

      {
        name: 'deployment',
        path: 'deployment',
        alias: 'devops_host',
        component: CrudComponent,
        beforeEnter: authGuard,
        props: {
          name: 'deployment',
          modelName: 'deployment',
          mode: 'remote',
          options: {
            url: 'deployment',
            stats: true,
            viewPath: 'deployment/:id',
            modalMode: 'slide',
            actions: {
              create: true,
              view: true,
              edit: false,
              delete: true,
            },
          },
        },
      },

      {
        name: 'deployment-view',
        path: 'deployment/:id',
        alias: 'devops_host/:id',
        component: DeploymentDetailPage,
        props: {
          modelName: 'deployment',
        },
        meta: {},
      },
      {
        name: 'contact',
        path: 'contact',
        component: CrudComponent,
        beforeEnter: authGuard,
        props: {
          modelName: 'contact',
          options: {
            url: '/contact',
            stats: true,
            modalMode: 'slide',
            actions: {
              view: true,
              delete: true,
            },
          },
        },
        children: [
          {
            name: 'contact-view',
            path: ':id',
            component: CrudComponent,
            props: {
              name: 'contact',
              options: {
                modalMode: 'slide',
                actions: {
                  view: true,
                  delete: true,
                },
              },
            },
          },
          {
            name: 'contact-edit',
            path: ':id/edit',
            component: CrudComponent,
            props: {
              modelName: 'contact',
              options: {
                modalMode: 'slide',
                actions: {
                  edit: true,
                  view: true,
                  delete: true,
                },
              },
            },
          },
        ],
      },

      {
        name: 'permanent_token',
        path: 'permanent_token',
        component: CrudComponent,
        beforeEnter: authGuard,
        props: {
          modelName: 'permanent_token',
          options: {
            url: '/permanent_token',
            stats: true,
            actions: {
              create: true,
              edit: false,
              view: false,
              delete: true,
            },
          },
        },
      },
      {
        name: 'absence',
        path: 'absence',
        component: AbsenceComponent,
        beforeEnter: authGuard,
        props: {
          modelName: 'absence',
          options: {
            // url: '/crud/absence', => definied in the api
            stats: true,
            modalMode: 'slide',
            actions: {
              create: true,
              edit: true,
              view: true,
              delete: true,
              filter: false,
            },
            queryParams: {
              sort: { fromDate: 'desc' },
            },
          },
        },
        children: [
          {
            name: 'absence-view',
            path: ':id',
            props: {
              modelName: 'absence',
              options: {
                url: '/crud/absence',
                stats: true,
                modalMode: 'slide',
                actions: {
                  create: true,
                  edit: true,
                  view: true,
                  delete: true,
                  filter: false,
                },
              },
            },
          },
          {
            name: 'absence-edit',
            path: ':id/edit',
            props: {
              modelName: 'absence',
              options: {
                url: '/crud/absence',
                stats: true,
                modalMode: 'slide',
                actions: {
                  create: true,
                  edit: true,
                  view: true,
                  delete: true,
                  filter: false,
                },
              },
            },
          },
        ],
      },
      {
        name: 'remote-day',
        path: 'remote-day',
        component: RemoteDayComponent,
        beforeEnter: authGuard,
        props: {
          modelName: 'remote_day',
          options: {
            stats: true,
            modalMode: 'slide',
            actions: {
              create: true,
              edit: true,
              view: true,
              delete: true,
              filter: false,
            },
            queryParams: {
              sort: { date: 'desc' },
            },
          },
        },
        children: [
          {
            name: 'remote-day-view',
            path: ':id',
            props: {
              modelName: 'remote_day',
              options: {
                url: '/crud/remote_day',
                stats: true,
                modalMode: 'slide',
                actions: {
                  create: true,
                  edit: true,
                  view: true,
                  delete: true,
                  filter: false,
                },
              },
            },
          },
          {
            name: 'remote-day-edit',
            path: ':id/edit',
            props: {
              modelName: 'remote_day',
              options: {
                url: '/crud/remote_day',
                stats: true,
                modalMode: 'slide',
                actions: {
                  create: true,
                  edit: true,
                  view: true,
                  delete: true,
                  filter: false,
                },
              },
            },
          },
        ],
      },
      {
        path: 'setting',
        component: CrudComponent,
        beforeEnter: authGuard,
        props: {
          modelName: 'setting',
          options: {
            url: '/crud/setting',
            stats: true,
            modalMode: 'slide',
            actions: {
              create: true,
              edit: true,
              view: true,
              delete: true,
            },
          },
        },
        children: [
          {
            name: 'setting-view',
            path: ':id',
            component: CrudComponent,
            props: {
              modelName: 'setting',
              options: {
                url: '/:id',
                modalMode: 'slide',
                stats: true,
                actions: {
                  create: true,
                  edit: true,
                  view: true,
                  delete: true,
                },
              },
            },
          },
          {
            name: 'setting-edit',
            path: ':id/edit',
            component: CrudComponent,
            props: {
              modelName: 'setting',
              options: {
                url: '/:id/edit',
                stats: true,
                actions: {
                  create: true,
                  edit: true,
                  view: true,
                  delete: true,
                },
              },
            },
          },
        ],
      },
      {
        path: 'crud/:name',
        name: 'Crud Page',
        component: CrudComponent,
        props: true,
        children: [
          {
            name: 'crud-view',
            path: ':name/:id',
            component: CrudComponent,
            props: true,
          },
          {
            name: 'crud-edit',
            path: ':name/:id/edit',
            component: CrudComponent,
            props: true,
          },
        ],
      },
      {
        name: 'Admin User',
        path: 'admin/user',
        component: CrudComponent,
        beforeEnter: roleGuard('ADMIN'),
        props: {
          modelName: 'user',
          options: {
            stats: true,
            url: '/user',
            modalMode: 'slide',
            actions: {
              create: rolesMixin.methods.userHasRole('TEAMLEAD', store),
              view: true,
              edit: true,
            },
            columnsDisplayed: 9,
          },
        },
        children: [
          {
            name: 'user-view',
            path: ':id',
            component: CrudComponent,
            props: {
              modelName: 'user',
              options: {
                stats: true,
                url: '/user',
                actions: {
                  create: rolesMixin.methods.userHasRole('TEAMLEAD', store),
                  view: true,
                  edit: true,
                },
              },
            },
          },
          {
            name: 'user-edit',
            path: ':id/edit',
            component: CrudComponent,
            props: {
              modelName: 'user',
              options: {
                stats: true,
                url: '/user',
                actions: {
                  view: true,
                  edit: true,
                },
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: '*',
    component: NotFound,
  },
];

/**
* Asynchronously load view (Webpack Lazy loading compatible)
* The specified component must be inside the Views folder
* @param  {string} name  the filename (basename) of the view to load.
function view(name) {
 var res= require('../components/Dashboard/Views/' + name + '.vue');
 return res;
};* */

if (config.automaticModels && config.automaticModels.length) {
  const appRoute = routes.find((route) => route.path === '/app');
  config.automaticModels.forEach((model) => {
    const options = {
      mode: 'remote',
      url: null,
      columns: null,
      viewPath: null,
      stats: false,
      modalMode: 'slide', // fade | slide
      nestedDisplayMode: 'list', // list | object
      actions: {
        noActions: false,
        create: false,
        edit: false,
        filter: true,
        dateFilter: true,
        view: true,
        delete: false,
        import: false,
        export: true,
      },
    };
    appRoute.children.push({
      name: model.name,
      path: model.route || model.name,
      component: CrudComponent,
      beforeEnter: authGuard,
      props: {
        identity: model.name,
        name: model.name,
        modelName: model.modelName || model.name,
        options: _.merge(options, model.options, model.listOptions),
        nestedSchemas: model.nestedSchemas,
        nestedDisplayMode: model.nestedDisplayMode,
      },
      children: [
        {
          name: `${model.name}-view`,
          path: ':id',
          component: CrudComponent,
          props: {
            identity: model.name,
            name: model.name,
            modelName: model.modelName || model.name,
            options: _.merge(options, model.options, model.viewOptions),
            nestedSchemas: model.nestedSchemas,
            nestedDisplayMode: model.nestedDisplayMode,
          },
        },
        {
          name: `${model.name}-edit`,
          path: ':id/edit',
          component: CrudComponent,
          props: {
            identity: model.name,
            name: model.name,
            modelName: model.modelName || model.name,
            options: _.merge(options, model.options, model.editOptions),
            nestedSchemas: model.nestedSchemas,
            nestedDisplayMode: model.nestedDisplayMode,
          },
        },
      ],
    });
  });
}

export default routes;
